import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import {Container, Row, Col, Button, Alert, Accordion, Card, Table, thead, tr, th, tbody, td, Navbar,  NavDropdown, Form, FormControl, h1, span, br, a} from 'react-bootstrap';
import SortableTree,{removeNodeAtPath,find,getTreeFromFlatData,getFlatDataFromTree,getNodeAtPath,changeNodeAtPath,toggleExpandedForAll} from 'react-sortable-tree';

import turtlelogo from '../assets/images/turtlelogo.png';

import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as FcIcons from "react-icons/fc";

const Nav = styled.div`
  background: #000;
  height: 80px;
  width:100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Logout = styled.div`
  
  font-size: 2rem;
  height: 80px;
  width:100%;
  display: flex;
  justify-content:flex-end;
  align-items: center;
  padding-right:12px;
`;



const SidebarNav = styled.nav`
  background: #000;
  width: 250px;
  height: 100vh;
  display: flex;
  overflow-y:scroll;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;




class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
           
      ready: false,
      sidebar:false,
      SidebarData:[]

    }
  }
  componentDidMount() { 

  
    var menuroles=JSON.parse(localStorage.getItem("MenuRoles") || "[]");
    var selectedroleid=localStorage.getItem("UserRoleID");
   
    //gbconsole.log('menuroles=',menuroles)
    //gbconsole.log('selectedroleid=',selectedroleid)
    var tData=[];
    for(var i=0;i<menuroles.length;i++){
      if(menuroles[i]['RoleID']==selectedroleid){
        var MenuItem={};
        MenuItem['id']=menuroles[i]['MenuID'];
        MenuItem['title']=menuroles[i]['MenuText'];
        MenuItem['path']=menuroles[i]['MenuUrl']==null?'':menuroles[i]['MenuUrl'];
        
        MenuItem['icon']=menuroles[i]['MenuIcon'];;
        MenuItem['parent']=menuroles[i]['ParentMenuID'];
        
        tData.push(MenuItem);

      }
     

    }
    const tData1 = getTreeFromFlatData({
      flatData: tData,
      getKey: (node) => node.id,
      getParentKey: (node) => node.parent,
      rootKey: null
    })
    //gbconsole.log('treedata=...',tData1)
    //subNav
    var sidebardata=[];
    for(i=0;i<tData1.length;i++){
      var MenuItem={};
      MenuItem['id']=tData1[i]['id'];
      MenuItem['title']=tData1[i]['title'];
      MenuItem['path']=tData1[i]['path'];
      MenuItem['icon']=tData1[i]['icon'];
      MenuItem['iconClosed']=<RiIcons.RiArrowDownSFill />;
      MenuItem['iconOpened']=<RiIcons.RiArrowUpSFill />;
      try{
        if(tData1[i]['children']!=undefined||tData1[i]['children'].length>0)
       MenuItem['subNav']=tData1[i]['children'];
      }catch(e){}
      
      sidebardata.push(MenuItem);

    }
    this.setState({SidebarData:sidebardata},()=>{
      //gbconsole.log('sidebardata====',this.state.SidebarData)
    });
    

  }
  showSidebar=(event)=>{
   this.setState({sidebar:!this.state.sidebar});
  }
  render() {
    const {
      props,
    } = this;

   let projectname='QDN APPLICATION';
   //console.log('projectname=',projectname)


    return (
      <>
        <IconContext.Provider value={{ color: '#fff' }}>
          <Nav>
            <NavIcon to='#'>
              <FaIcons.FaBars onClick={this.showSidebar} />
            </NavIcon>
             <div className='projectname'>{projectname}</div>
            <Logout >
              <img src={turtlelogo} width='100px' style={{marginRight:'20px'}}  />
              <AiIcons.AiOutlineLogout onClick={()=>{props.refreshCallback('logout')}} />
            </Logout>
          </Nav>
          <SidebarNav sidebar={this.state.sidebar}>
            <SidebarWrap>
              <NavIcon to='#'>
                <AiIcons.AiOutlineClose onClick={this.showSidebar} />
              </NavIcon>
              {/* {SidebarData.map((item, index) => {
                return <SubMenu item={item} currentLink={props.currentLink} parentprops={props.refreshCallback} menuclick={this.showSidebar} key={index} />;
              })} */}

              {this.state.SidebarData.map((item, index) => {
                return <SubMenu item={item} currentLink={props.currentLink} parentprops={props.refreshCallback} menuclick={this.showSidebar} key={index} />;
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
      </>
    );
  }
}

export default Sidebar;
