import React, { Component } from 'react'
import './DataGrid.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';
import * as AiIcons from 'react-icons/ai';
import {Form,Pagination,Button} from 'react-bootstrap';
import RoleEditModal from '../RoleEditModal';
import styled from 'styled-components';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  const TableHeaderDiv=styled.div`
  display:flex;
  justify-content:flex-start !important;
  margin:10px;
  flex-wrap: wrap;
  border:1px solid beige;
  padding-bottom:5px;
  margin-left:1px;
  margin-right:1px;
  border:1px solid grey;
  `;
  const Div1=styled.div`
  display:flex;
  flex:10%;
  padding-top:9px;
  padding-left:10px;
  `;
  const Div2=styled.div`
  display:flex;
  flex:10%;
  padding-top:5px;
  `;
  const Div3=styled.div`
  display:flex;
  flex:1%;
  padding-top: 5px;
   
  `;
  const Div4=styled.div`
  flex:20%;
  display:flex;
  `;
  const Div5=styled.div`
  flex:5%;
  display:flex;
  padding-top:5px;
  `;
  const Div6=styled.div`
  flex:5%;
  display:flex;
  padding-top: 25px;
  
  `;

const PaginationDiv=styled.div`
display:flex;
justify-content:flex-end !important;
margin:10px;`;
const exportToCSV = (apiData, fileName) => {
    const fileExtension=".xlsx";
    const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
 
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

export class DataGrid extends Component {

    /*
    FieldName
    FieldLabel
    FilterType
    IsVisible
    FilterStatus
    FilterApplied
    */
    constructor(props) {
	  super(props);
        this.state = {
           
            ready: false,
			searchtext:'',
			sortfield:'',
			sorttype:'',
            showmodal:false,
            roleeditid:0,
            totalvisiblecolumns:[],
            actualvisiblecolumns:[],
            actualsearchcolumns:[],
            allcolumns:[],
            selectedcolumns:[],
            selectedrows:[],
            paginationrowstart:0,
            paginationrowend:9,
            noofrows:10,
            activepage:0,
            disabledropdown1:'',
            actualvisiblecolumnsfilter:[],
            fielddetails:[],
            showadvancefilter:false,
            filteredrows:[],
            generalfilter:['Enable', 'Disable', 'Clear', 'Clear all'],
            textfilter:['Contains' , 'Does not contain' , 'Equals' , 'Does not equal' , 'Empty' , 'Not empty' , 'Starts with' , 'Ends with'],
            numberfilter:['Greater than', 'Greater than or equal', 'Less than', 'Less than or equal', 'Equals', 'Does not equal','In range','Not in range'],
            datefilter:['After','After or on','Before', 'Before or on','Equals', 'Does not equal', 'In range', 'Not in range'],
            multiselectfilter:['In list','Not in list','Equals','Does not equal']
          }
	}
   
       

    
	componentDidMount() { 
        //gbconsole.log('tableid in datagrid==',this.props.tableid);
        
         var filteredrows=[...this.props.rowsarray];
         this.setState({filteredrows:filteredrows},()=>{
            console.log('filtered rows=',this.state.filteredrows)
             this.initialize();
         });
       // filteredrows.push();
       
	}

    initialize(){
        var dropdownfields=JSON.parse(localStorage.getItem("DropDownFields") || "[]");

        //gbconsole.log('filtered rows=',this.state.filteredrows);
       //gbconsole.log('rowsarray=',this.props.rowsarray);

        var fielddetails=[];
        console.log('parenttableid=',this.props.parenttableid);
        for(var i=0;i<dropdownfields.length;i++){
            var fieldobj={};
            
            if(this.props.tableid==dropdownfields[i]['DropdownTableID']||this.props.parenttableid==dropdownfields[i]['DropdownTableID']){
          
               fieldobj['ID']=dropdownfields[i]['ID'];     
               fieldobj['FieldName']=dropdownfields[i]['FieldName'];
               fieldobj['FieldLabel']=dropdownfields[i]['FieldLabel'];
               fieldobj['FilterType']=dropdownfields[i]['FilterType'];
               fieldobj['IsVisible']=true;
               fieldobj['FilterStatus']='';
               fieldobj['FilterValue']='';
               fieldobj['FilterValue2']='';
               fieldobj['FilterApplied']=false;
               console.log(fieldobj);
               fielddetails.push(fieldobj);

            }
            
        }
        //gbconsole.log('fielddetails=',fielddetails);

		//let headerarray=this.props.rowsarray.length>0?Object.keys(this.props.rowsarray[0]):[];
        let headerarray=this.state.filteredrows.length>0?Object.keys(this.state.filteredrows[0]):[];
        this.setState({allcolumns:headerarray,fielddetails:fielddetails});
        
		var acvisfilarray=[];
        if(this.props.columns!=undefined){
            
            for(var i=0;i<this.props.columns.length;i++){
                acvisfilarray[i]=false;
            }
            this.setState({totalvisiblecolumns:this.props.columns});
            this.setState({actualvisiblecolumns:this.props.columns},()=>{this.setState({sortfield:this.state.actualvisiblecolumns[0],actualsearchcolumns:this.state.actualvisiblecolumns,sorttype:'ASC',actualvisiblecolumnsfilter:acvisfilarray,ready:true});});
            
           
        }else
        {
           // let headerarray=Object.keys(this.props.rowsarray[0]);
           for(var i=0;i<headerarray.length;i++){
            acvisfilarray[i]=false;
        }
            this.setState({totalvisiblecolumns:headerarray});
            this.setState({actualvisiblecolumns:headerarray},()=>{this.setState({sortfield:this.state.actualvisiblecolumns[0],actualsearchcolumns:this.state.actualvisiblecolumns,actualvisiblecolumnsfilter:acvisfilarray,sorttype:'ASC',ready:true});});
        }
       // //gbconsole.log(this.state.ready);

    }
	 handleChange = (event) => {
		   this.setState({[event.target.name]: event.target.value})
		   if(event.target.targetname=="searchtext"){
               this.setState({paginationrowstart:0,paginationrowend:9})
           }
	 }
   changeVisibility(index){
       
       //gbconsole.log('index=',index,'----- columns filter array= ',this.state.actualvisiblecolumnsfilter,'--actual visible columns==',this.state.actualvisiblecolumns);
    var acvisfilarray=[...this.state.actualvisiblecolumnsfilter];
    
        acvisfilarray[index]=acvisfilarray[index]=!this.state.actualvisiblecolumnsfilter[index];
    
  this.setState({actualvisiblecolumnsfilter:acvisfilarray})
   }
	 handleChange2 = (event) => {    
       // //gbconsole.log('handlechange2 called')
      //  //gbconsole.log(event.target)
        const targetname=event.target.name;
        const targetvalue=event.target.value;
        const selindex=event.target.selectedIndex;
       // //gbconsole.log('targetname=',targetname,'selindex=',selindex)
        this.setState({[event.target.name]: event.target.value},function() {

            if(event.target.name=='noofrows'){
                this.setState({'paginationrowend':parseInt(''+this.state.paginationrowstart)+parseInt(''+this.state.noofrows)-1},()=>{

                    //gbconsole.log('rowstart:',this.state.paginationrowstart);
                    //gbconsole.log('rowend:',this.state.paginationrowend);
                });
            }

		});
        
	}
    handleChange4 = async (event) => {

        var checkboxes=document.getElementsByTagName("input")
        for(var i=1;i<checkboxes.length;i++){
            //gbconsole.log('name=',checkboxes[i].name);
            //gbconsole.log('value=',checkboxes[i].value);
          if(checkboxes[i].type=='checkbox'  && checkboxes[i].name=='datagrid' && checkboxes[i].id!=''  && event.target.checked!=checkboxes[i].checked){
            checkboxes[i].click();
           await sleep(10)
            
           
          }
        }

    }
    handleChange3 = (event) => {
        //gbconsole.log('checkbox event=',event.target.checked," id=",event.target.id)
        let selectedrows=[];
        if(this.state.selectedrows!=undefined){
            for(var i=0;i<this.state.selectedrows.length;i++){
                if(this.state.selectedrows[i]!=event.target.id){
                    selectedrows.push(this.state.selectedrows[i]);
                }
               }
               

        }
        if(event.target.checked==true){
            selectedrows.push(event.target.id);
        }
        
        this.setState({selectedrows:selectedrows},()=>{
           //gbconsole.log('selectedrows=',this.state.selectedrows)
           this.props.setselectedarray(this.state.selectedrows);
        
        });
    }
    paginationclicked=(event,tquotient,tremainder)=>{
        //gbconsole.log('i=',event.target.text)
        var activepage=parseInt(event.target.text)-1;
        var rowstart=(parseInt(event.target.text)-1)*this.state.noofrows;
        let rowend=parseInt(rowstart )+parseInt(''+this.state.noofrows)-1;
       //gbconsole.log('rowend--=',rowend,'rowstart=',this.state.paginationrowstart);
       if(activepage==tquotient&&tremainder>0){
           
           rowend=parseInt(rowstart )+parseInt(''+tremainder)-1;
           
       }
       
        this.setState({activepage:parseInt(event.target.text)-1},()=>{

            //gbconsole.log('quotient=',tquotient,' rowstart=',rowstart,' rowend=',rowend)
            this.setState({'paginationrowstart':rowstart,'paginationrowend':rowend})

        })
        // var rowstart=0;
        // if(i==tquotient){
        //    // this.setState({'paginationrowstart':parseInt(i+1)*this.state.noofrows})
        //     rowstart=parseInt(i+1)*this.state.noofrows;

        // }
        // else{
            
        // }
      //  //gbconsole.log('pageclicked rowstart=',rowstart);

    }
    applyfilter(){
        var fieldtls=[...this.state.fielddetails];
        var rowsarray=[...this.props.rowsarray];
        //gbconsole.log('apply filter=',fieldtls)
        //gbconsole.log('actual visible columns=',this.state.actualvisiblecolumns)
        var rowsfiltered=[];
        
        for(var j=0;j<rowsarray.length;j++){
            var flag=true;
            for(var i=0;i<fieldtls.length;i++){
                if(this.state.actualvisiblecolumns.includes(fieldtls[i]['FieldName'])){
                    //gbconsole.log(fieldtls[i])
                    if(fieldtls[i]['FilterType']!=''){
                        if(fieldtls[i]['FilterStatus']=='Contains'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(rowsarray[j][fieldtls[i]['FieldName']].indexOf(fieldtls[i]['FilterValue'])==-1){
                                flag=false;
                                break;
                            }
                        }

                        if(fieldtls[i]['FilterStatus']=='Does not contain'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(rowsarray[j][fieldtls[i]['FieldName']].indexOf(fieldtls[i]['FilterValue'])!=-1){
                                flag=false;
                                break;
                            }
                        }

                        if(fieldtls[i]['FilterStatus']=='Equals'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(rowsarray[j][fieldtls[i]['FieldName']]!=fieldtls[i]['FilterValue']){
                                flag=false;
                                break;
                            }
                        }

                        if(fieldtls[i]['FilterStatus']=='Does not equal'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(rowsarray[j][fieldtls[i]['FieldName']]==fieldtls[i]['FilterValue']){
                                flag=false;
                                break;
                            }
                        }

                        if(fieldtls[i]['FilterStatus']=='Empty'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(rowsarray[j][fieldtls[i]['FieldName']]!=''){
                                flag=false;
                                break;
                            }
                        }

                        if(fieldtls[i]['FilterStatus']=='Not empty'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(rowsarray[j][fieldtls[i]['FieldName']]==''){
                                flag=false;
                                break;
                            }
                        }

                        if(fieldtls[i]['FilterStatus']=='Starts with'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(!rowsarray[j][fieldtls[i]['FieldName']].startsWith(fieldtls[i]['FilterValue'])){
                                flag=false;
                                break;
                            }
                        }

                        if(fieldtls[i]['FilterStatus']=='Ends with'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(!rowsarray[j][fieldtls[i]['FieldName']].endsWith(fieldtls[i]['FilterValue'])){
                                flag=false;
                                break;
                            }
                        }

                        

                        if(fieldtls[i]['FilterStatus']=='Greater than'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(!(rowsarray[j][fieldtls[i]['FieldName']]>fieldtls[i]['FilterValue'])){
                                flag=false;
                                break;
                            }
                        }

                       

                        if(fieldtls[i]['FilterStatus']=='Greater than or equal'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(!(rowsarray[j][fieldtls[i]['FieldName']]>=fieldtls[i]['FilterValue'])){
                                flag=false;
                                break;
                            }
                        }

                        

                        if(fieldtls[i]['FilterStatus']=='Less than'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(!(rowsarray[j][fieldtls[i]['FieldName']]<fieldtls[i]['FilterValue'])){
                                flag=false;
                                break;
                            }
                        }

                       

                        if(fieldtls[i]['FilterStatus']=='Less than or equal'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            if(!(rowsarray[j][fieldtls[i]['FieldName']]<=fieldtls[i]['FilterValue'])){
                                flag=false;
                                break;
                            }
                        }

                        if(fieldtls[i]['FilterStatus']=='After'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            const instancedate1 = new Date(rowsarray[j][fieldtls[i]['FieldName']])
                            //gbconsole.log('filter value=',fieldtls[i]['FilterValue'])
                            const instancedate2 = new Date(fieldtls[i]['FilterValue'])
                            if(!(instancedate1>instancedate2)){
                                flag=false;
                                break;
                            }
                        }
                     if(fieldtls[i]['FilterStatus']=='After or on'){
                        //gbconsole.log('filteredrows=',rowsarray)
                        const instancedate1 = new Date(rowsarray[j][fieldtls[i]['FieldName']])
                        //gbconsole.log('filter value=',fieldtls[i]['FilterValue'])
                        const instancedate2 = new Date(fieldtls[i]['FilterValue'])
                        if(!(instancedate1>=instancedate2)){
                            flag=false;
                            break;
                        }
                        }

                        if(fieldtls[i]['FilterStatus']=='Before'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            const instancedate1 = new Date(rowsarray[j][fieldtls[i]['FieldName']])
                            //gbconsole.log('filter value=',fieldtls[i]['FilterValue'])
                            const instancedate2 = new Date(fieldtls[i]['FilterValue'])
                            if(!(instancedate1<instancedate2)){
                                flag=false;
                                break;
                            }
                        }

                        if(fieldtls[i]['FilterStatus']=='Before or on'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            const instancedate1 = new Date(rowsarray[j][fieldtls[i]['FieldName']])
                            //gbconsole.log('filter value=',fieldtls[i]['FilterValue'])
                            const instancedate2 = new Date(fieldtls[i]['FilterValue'])
                            if(!(instancedate1<=instancedate2)){
                                flag=false;
                                break;
                            }
                        }

                        
                       
                        if(fieldtls[i]['FilterStatus']=='In range'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            const instancedate1 = new Date(rowsarray[j][fieldtls[i]['FieldName']])
                            //gbconsole.log('filter value=',fieldtls[i]['FilterValue'])
                            const instancedate2 = new Date(fieldtls[i]['FilterValue'])
                            const instancedate3 = new Date(fieldtls[i]['FilterValue2'])
                            if(!(instancedate1>=instancedate2&&instancedate1<=instancedate3)){
                                flag=false;
                                break;
                            }
                        }

                       
                        if(fieldtls[i]['FilterStatus']=='Not in range'){
                            //gbconsole.log('filteredrows=',rowsarray)
                            const instancedate1 = new Date(rowsarray[j][fieldtls[i]['FieldName']])
                            //gbconsole.log('filter value=',fieldtls[i]['FilterValue'])
                            const instancedate2 = new Date(fieldtls[i]['FilterValue'])
                            const instancedate3 = new Date(fieldtls[i]['FilterValue2'])
                            if((instancedate1>=instancedate2&&instancedate1<=instancedate3)){
                                flag=false;
                                break;
                            }
                        }
                       


                    }
                }
              }
              if(flag==true){
                rowsfiltered.push(rowsarray[j]);
              }

        }
        this.setState({filteredrows:rowsfiltered})
    }

    getfilterstatus(index){

        var fielddetails=[... this.state.fielddetails];
        // //gbconsole.log(fielddetails[1]['FieldName'])
         var filterstatus='';
         for(var i=0;i<fielddetails.length;i++){
             
             if(fielddetails[i]['FieldName']==this.state.actualvisiblecolumns[index]){
                filterstatus=fielddetails[i]['FilterStatus'];
             break;
         }
         }
         return filterstatus;


    }


    gettypeoffilter(index){
        var fielddetails=[... this.state.fielddetails];
       // //gbconsole.log(fielddetails[1]['FieldName'])
        var filtertype='';
        for(var i=0;i<fielddetails.length;i++){
            
            if(fielddetails[i]['FieldName']==this.state.actualvisiblecolumns[index]){
            filtertype=fielddetails[i]['FilterType'];
            break;
        }
        }
        return filtertype;

    }
    getfiltertype(index){
        var fielddetails=[... this.state.fielddetails];
       // //gbconsole.log(fielddetails[1]['FieldName'])
        var filtertype='';
        var filterstatus='';
        var selectedindex='';
        for(var i=0;i<fielddetails.length;i++){
            
           if(fielddetails[i]['FieldName']==this.state.actualvisiblecolumns[index]){
           filtertype=fielddetails[i]['FilterType'];
           filterstatus=fielddetails[i]['FilterStatus'];
           //gbconsole.log('i=',i,' index=',index,' fieldname=',fielddetails[i]['FieldName'],' filtertype=',filtertype)
           selectedindex=i;
           break;}
        }
        var filterdropdown='';
        if(filtertype=='Text'){
            filterdropdown=this.state.textfilter.map((item,i)=>{
                if(filterstatus==this.state.textfilter[i])
                return <p><FaIcons.FaRegDotCircle />&nbsp;{this.state.textfilter[i]}</p>
                else
                return <p onClick={()=>{
                    var fdetails=[...this.state.fielddetails];
                    fdetails[selectedindex]['FilterStatus']=this.state.textfilter[i];
                    this.setState({fielddetails:fdetails})
                    this.applyfilter()
                }}><FaIcons.FaRegCircle />&nbsp;{this.state.textfilter[i]}</p>

            })

        }
        if(filtertype=='Date'){
            filterdropdown=this.state.datefilter.map((item,i)=>{
                if(filterstatus==this.state.datefilter[i])
                return <p><FaIcons.FaRegDotCircle />&nbsp;{this.state.datefilter[i]}</p>
                else
                return <p
                
                onClick={()=>{
                    var fdetails=[...this.state.fielddetails];
                    fdetails[selectedindex]['FilterStatus']=this.state.datefilter[i];
                    this.setState({fielddetails:fdetails})
                    this.applyfilter();
                }}

                ><FaIcons.FaRegCircle />&nbsp;{this.state.datefilter[i]}</p>

            })

        }

        if(filtertype=='Number'){
            filterdropdown=this.state.numberfilter.map((item,i)=>{
                if(filterstatus==this.state.numberfilter[i])
                return <p><FaIcons.FaRegDotCircle />&nbsp;{this.state.numberfilter[i]}</p>
                else
                return <p
                onClick={()=>{
                    var fdetails=[...this.state.fielddetails];
                    fdetails[selectedindex]['FilterStatus']=this.state.numberfilter[i];
                    this.setState({fielddetails:fdetails})
                    this.applyfilter();
                }}
                
                ><FaIcons.FaRegCircle />&nbsp;{this.state.numberfilter[i]}</p>

            })

        }

        return filterdropdown;
    }
    clearallfilter(){

        var fieldtls=[...this.state.fielddetails];
for(var i=0;i<fieldtls.length;i++){
fieldtls[i]['FilterStatus']='';
fieldtls[i]['FilterValue']='';
}
this.setState({fielddetails:fieldtls});
this.applyfilter();
var filtertextfields=document.getElementsByClassName('searchTextFilter');
for(var i = 0; i < filtertextfields.length; i++)
{
    filtertextfields[i].value='';
}

    }
    getSearchArray(searchobject){
        var arr=[];
        for(var i=0;i<this.state.actualsearchcolumns.length;i++){
         arr.push(searchobject[this.state.actualsearchcolumns[i]]);
        }
     return arr;
    }
    //Enquiry master module
    iscouncellorselected(fieldid,recordid){
        var dropdowns=JSON.parse(localStorage.getItem("DropDowns") || "[]");
        var fieldvalue='0';
        for(var i=0;i<dropdowns.length;i++){
            ////gbconsole.log(dropdowns[i])
        if(dropdowns[i]['FieldID']==fieldid&&dropdowns[i]['RowID']==recordid){
            fieldvalue=dropdowns[i]['Fieldvalue'];
           // break;
        }
        }
        //gbconsole.log('isselected',fieldvalue);
        return fieldvalue;

    }

    assigncouncellor(recordid,fieldid,fieldvalue){
    //gbconsole.log('recordid=',recordid,'fieldid=',fieldid,'fieldvalue=',fieldvalue);


    let postData = {
         
         
        "FieldID":fieldid,
        "FieldValue":fieldvalue,
        "RowID":recordid,
         }
       //gbconsole.log('postdata=',postData)
       let url = process.env.REACT_APP_DEBUG_URL+'/api/updatesinglefield';
       let token =localStorage.getItem("UserToken");
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
        body: JSON.stringify(postData)
      
    };
  
    fetch(url, requestOptions)
          .then(res => res.json())
          .then(result => {
            //gbconsole.log(result)          
            var status=result['status'];
            localStorage.setItem("DropDowns", JSON.stringify(result.dropdowns));
        }); 



    }
    getpagelinks(tquotient,tremainder){
        var active=2;
        var arr=[];
        var i=0;
        for( i=0;i<tquotient;i++){
          //  const j=i;
            arr.push(<Pagination.Item active={i === this.state.activepage} onClick={

                
            (event)=> this.paginationclicked(event,tquotient,tremainder)
    
                }>{i+1}</Pagination.Item>)

        }
        
        if(tremainder>0)arr.push(<Pagination.Item active={i === this.state.activepage}
            onClick={
                (event)=>this.paginationclicked(event,tquotient,tremainder)
    
                }
        >{i+1} </Pagination.Item>)
        return arr;
    }

    render() {
        
		let headerarray=[

 
"First Name",
"Last Name",
"Job Title"



]

let tablerowarray=[

{
FirstName:"James",
LastName:"Matman",
Jobtitle:"Chief Sandwich Eater"

} ,
{
FirstName:"The",
LastName:"Tick",
Jobtitle:"Crimefighter Sorta"

} ,
{
FirstName:"Avik",
LastName:"Roy",
Jobtitle:"Doctor"

} ,





]
     //Enquiry Module
     let constdropdown1values=[];
     if(this.props.showdatagriddropdown1=='yes'){
         constdropdown1values=JSON.parse(localStorage.getItem("UserRolesAssigned") || "[]");
        //gbconsole.log('user role datagrid',constdropdown1values);
     }

	//	//gbconsole.log('actualvisiblecolumns',this.state.actualvisiblecolumns)
		const tableheader=this.state.ready==true?this.state.actualvisiblecolumns.map((item,i)=>{

////gbconsole.log('actualvisiblecolumns',this.state.actualvisiblecolumns[i])

return <th onClick={()=>{this.setState({sortfield:this.state.actualvisiblecolumns[i],sorttype:this.state.sorttype==''?'ASC':this.state.sorttype=='ASC'?'DESC':'ASC'});
//gbconsole.log(this.state)
}}>{this.state.actualvisiblecolumns[i]} &#8593;&#8595; </th>
}):null

const tableheaderfilter=this.state.ready==true?this.state.actualvisiblecolumns.map((item,i)=>{

    ////gbconsole.log('actualvisiblecolumns',this.state.actualvisiblecolumns[i])
    var ss=false;
    var filterdropdown=this.getfiltertype(i);
    var typeoffilter=this.gettypeoffilter(i);
    var filterstatus=this.getfilterstatus(i);
    ////gbconsole.log('filter type=',filtertype);
    return <th >
        <input style={{width:'75%'}} className='searchTextFilter' type={typeoffilter=='Date'?'date':'text'} placeholder={this.state.actualvisiblecolumns[i]}   onChange={(event)=>{
        //gbconsole.log(event.target.value)
        var fidetails=[...this.state.fielddetails];
        for(var j=0;j<fidetails.length;j++){
        if(fidetails[j]["FieldName"]==this.state.actualvisiblecolumns[i]){
            fidetails[j]["FilterValue"]=event.target.value;
            this.setState({fielddetails:fidetails})
            break;

        }
        }
        //gbconsole.log(this.state.fielddetails)
        this.applyfilter();

    }} />&nbsp;

<input style={{width:'75%'}} className='searchTextFilter' type={typeoffilter=='Date'&&(filterstatus=='In range'||filterstatus=='Not in range')?'date':'hidden'} onChange={(event)=>{
        //gbconsole.log(event.target.value)
        var fidetails=[...this.state.fielddetails];
        for(var j=0;j<fidetails.length;j++){
        if(fidetails[j]["FieldName"]==this.state.actualvisiblecolumns[i]){
            fidetails[j]["FilterValue2"]=event.target.value;
            this.setState({fielddetails:fidetails})
            break;

        }
        }
        //gbconsole.log(this.state.fielddetails)
        this.applyfilter();

    }} />
    
    
    <BsIcons.BsFilter onClick={
       ()=>this.changeVisibility(i)
          
    }/>
    <div  className='filterdiv' style={this.state.actualvisiblecolumnsfilter[i]?{display:'block'}:{display:'none'}} onClick={
       ()=>this.changeVisibility(i)
          
    }>

   {/* <p > <input type="radio" value="Male" name="gender{i}" /> Enable</p>
   <p  >   <input   type="radio" value="Female" name="gender{i}" /> Disable</p>
   <p ><input type="radio" value="Other" name="gender{i}" /> Clear</p>
   <p ><input type="radio" value="Other" name="gender{i}" /> Clear all</p> */}
{
//     filtertype==undefined?null:(filtertype=='Text')?
//     <div>
//     <p><FaIcons.FaRegDotCircle />&nbsp;Contains</p>
// <p><FaIcons.FaRegCircle />&nbsp;Does not contain</p>
// <p><FaIcons.FaRegCircle />&nbsp;Equals</p>
// <p><FaIcons.FaRegCircle />&nbsp;Does not equal</p>
// <p><FaIcons.FaRegCircle />&nbsp;Empty</p>
// <p><FaIcons.FaRegCircle />&nbsp;Not empty</p>
// <p><FaIcons.FaRegCircle />&nbsp;Starts with</p>
// <p><FaIcons.FaRegCircle />&nbsp;Ends with</p>
// </div>:null
filterdropdown
}


<hr></hr>




   <p>Enable</p>
   <p className='disabled'>Disable</p>
   <hr></hr>
   <p onClick={()=>{

var fidetails=[...this.state.fielddetails];
for(var j=0;j<fidetails.length;j++){
if(fidetails[j]["FieldName"]==this.state.actualvisiblecolumns[i]){
    fidetails[j]["FilterValue"]='';
    fidetails[j]["FilterValue2"]='';
    fidetails[j]["FilterStatus"]='';
    this.setState({fielddetails:fidetails})
    break;

}
}
//gbconsole.log(this.state.fielddetails)
this.applyfilter();



   }}>Clear</p>
   <p onClick={()=>{this.clearallfilter()}}>Clear All</p>

  </div>
  
  </th>
    }):null
 

////gbconsole.log('tableheader=',tableheader)

//const tablerows=this.state.ready==true?this.props.rowsarray.filter(person => [this.getSearchArray(person).toString()].toString().toLowerCase().includes(this.state.searchtext.toLowerCase())
var qdnstatus='';
const tablerows=this.state.ready==true?this.state.filteredrows.filter(person => [this.getSearchArray(person).toString()].toString().toLowerCase().includes(this.state.searchtext.toLowerCase())
).sort((a, b) =>this.state.sortfield==''?null:this.state.sorttype=='ASC'? a[this.state.sortfield] > b[this.state.sortfield] ? 1 : -1:a[this.state.sortfield] < b[this.state.sortfield] ? 1 : -1).map((filteredPerson,indexnum)=>{
  // //gbconsole.log(indexnum);
   if(indexnum>=this.state.paginationrowstart&&indexnum<=this.state.paginationrowend){
    return <tr>
    {this.props.showselection!=undefined&&this.props.showselection==true?<td> 
        <Form.Check id={filteredPerson[this.state.allcolumns[0]]} type="checkbox" name="datagrid" onChange={this.handleChange3} /></td>:null}
    {this.state.actualvisiblecolumns.map((item,i)=>{
if(this.state.actualvisiblecolumns[i]=='QDN STATUS'){
    qdnstatus=filteredPerson[this.state.actualvisiblecolumns[i]];
    
}
return <td title={this.state.actualvisiblecolumns[i]}>{filteredPerson[this.state.actualvisiblecolumns[i]]}</td>
})}
{this.props.showeditbutton==undefined||(this.props.showeditbutton!=undefined&&this.props.showeditbutton==true)?<td onClick={()=>{this.setState({roleeditid:filteredPerson[this.state.allcolumns[0]],showmodal:true});
//gbconsole.log('editid=',filteredPerson[this.props.editfield==undefined?this.state.allcolumns[0]:this.props.editfield],this.state.showmodal,this.state.allcolumns[0]);
this.props.seteditid(filteredPerson[this.props.editfield==undefined?this.state.allcolumns[0]:this.props.editfield])}}><FaIcons.FaEdit /></td>:null}
{this.props.showdeletebutton!=undefined&&this.props.showdeletebutton=='yes'?<td><FaIcons.FaTrash /></td>:null}
{this.props.activationcallback!=undefined?<td><Button variant="primary" size="sm"  type="button" style={{width:'100px'}} onClick={()=>{this.props.activationcallback(filteredPerson[this.state.allcolumns[0]])}}>{filteredPerson["Status"]=="Active"?"Deactivate":"Acitivate"}</Button></td>:null}
{this.props.showdatagriddropdown1=='yes' && this.props.councellordropdown=='yes'&&localStorage.getItem('UserRoleID')!=18?
<td>

 <Form.Control as="select" id="selectedrole"   className="margin-5 w-60" onChange={(event)=>{this.assigncouncellor(filteredPerson[this.state.allcolumns[0]],139,event.target.value)}}   >
      <option key ={0} value={0}>Assign Councellor</option>
      {
          
                  (()=>{
                      var isdisabled=localStorage.getItem('UserRoleID')==18?true:false;
                    if(constdropdown1values.length>0){
                    
                      let dropvalues=[];
                        
                      for(var v=0;v<constdropdown1values.length;v++){
                          if(constdropdown1values[v]['RoleID']==18){
                              
                              
                            dropvalues.push(<option   key={constdropdown1values[v]['UserID']} disabled={isdisabled} selected={this.iscouncellorselected('139',filteredPerson[this.state.allcolumns[0]])==constdropdown1values[v]['UserID']?true:false} value={constdropdown1values[v]['UserID']}>{constdropdown1values[v]['FirstName']+' '+(constdropdown1values[v]['MiddleName']==null?'':constdropdown1values[v]['MiddleName'])+' '+constdropdown1values[v]['LastName']} </option>);
                        

                          }
                       
                                             
                        
                        
    
                    }
                   
                    return dropvalues;
    
                    }
                    else
                    return null;
                      
                        
                   })()
                }
 </Form.Control>
 </td>

:null}

{this.props.showdatagriddropdown1=='yes'?
<td>
<Button variant="primary" size="sm"  type="button" onClick={()=>{this.props.followupcallback(filteredPerson[this.state.allcolumns[0]])}}>Followups</Button>   
</td>
:null}

{this.props.showdiscdetail=='yes'?
<td>
<Button variant="primary" size="sm"  type="button" onClick={()=>{this.props.followupcallback(filteredPerson[this.state.allcolumns[0]])}} >QdnDetails</Button>   
</td>
:null}

{this.props.showdatagridevaluation=='yes'?
<td>
<Button variant="primary" size="sm"  type="button" onClick={()=>{this.props.followupcallback(filteredPerson[this.state.allcolumns[0]])}}>View Marks</Button>   
</td>
:null}
</tr>

   }else return null;
    
}):null

const tquotient=this.state.ready==true?Math.floor(tablerows.length/this.state.noofrows):0;

const tremainder=this.state.ready==true?tablerows.length % this.state.noofrows:0;

const pagelink=this.state.ready==true?this.getpagelinks(tquotient,tremainder):'';
   
   

if(this.state.ready==false)
return(<div></div>)

else
        return (
          
          
            <div style={{margin:'15px',marginLeft:'0px',marginRight:'0px'}}>
                <div className='tableheader'>
                
                <Div6>
    {this.props.showselection!=undefined&&this.props.showselection==true?<div className='datagridSelectAll'>Select all&nbsp;
                    <input type='checkbox' id='selectall' onChange={this.handleChange4}/></div>:null}

    </Div6>
                
                <Div3>
                <a >
                <BsIcons.BsCloudDownload className="downloadexcel" onClick={(e) => 
                // exportToCSV(this.props.rowsarray.filter(person => person[this.state.actualvisiblecolumns[0]].toString().toLowerCase().includes(this.state.searchtext.toLowerCase())
                //exportToCSV(this.state.filteredrows.filter(person => person[this.state.actualvisiblecolumns[0]].toString().toLowerCase().includes(this.state.searchtext.toLowerCase())
                exportToCSV(this.state.filteredrows.filter(person => [this.getSearchArray(person).toString()].toString().toLowerCase().includes(this.state.searchtext.toLowerCase())
).sort((a, b) =>this.state.sortfield==''?null:this.state.sorttype=='ASC'? a[this.state.sortfield] > b[this.state.sortfield] ? 1 : -1:a[this.state.sortfield] < b[this.state.sortfield] ? 1 : -1), 'file1')}/>
</a>
                </Div3>
                
                <Div5>
                <Form.Control as="select" className="margin-top-left-5 " onChange={this.handleChange2} name="noofrows">
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="30">30</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="200">200</option>
      <option value="500">500</option>
      <option value="1000">1000</option>
     
    </Form.Control>
                </Div5>
                
                 <Div1>
                 <DropdownMultiselect
                  placeholderMultipleChecked="show columns"
        options={this.state.totalvisiblecolumns}
        name="selectcolumns" selected={this.state.actualvisiblecolumns}
        handleOnChange={(selected) => {
            this.setState({actualvisiblecolumns:selected})
          }}
      />
                </Div1>   

                <div className="datagrid-search-in-columns">
               
      <DropdownMultiselect
      placeholderMultipleChecked=" search in columns"
        options={this.state.actualvisiblecolumns}
        name="searchcolumns" selected={this.state.actualsearchcolumns}
        handleOnChange={(selected) => {
            this.setState({actualsearchcolumns:selected})
          }}
      />
                </div>

                

                <div className="datagrid-search">
                <TextField className="txtfld" id="standard-basic" label="Search" name="searchtext" onChange={this.handleChange} value={this.state.searchtext} />
                
                </div>
                <div className='datagrid-adv-filter' 
                 style={this.props.advancefilter==1?{display:'block'}:{display:'none'}}
                 onClick={()=>this.setState({showadvancefilter:!this.state.showadvancefilter})}>
                  Advance Filter
                </div>

                

                <div className="datagrid-sort">

                <Form.Control as="select" className="margin-5 sort-by-dropdown w-70" onChange={this.handleChange2} name="sortfield">
      

	  {
                                (() => {
                                    if(this.state.actualvisiblecolumns.length !== 0) {
                                        let sortfields = [];
                                        
                                        for(var i=0;i<this.state.actualvisiblecolumns.length;i++){
                                            sortfields.push(<option key={this.state.actualvisiblecolumns[i]} value={this.state.actualvisiblecolumns[i]}>Sort by-{this.state.actualvisiblecolumns[i]}</option>);
                                            
                                        }
                                        return sortfields;
                                    }

                                })()
                                }   

    </Form.Control>
	<Form.Control as="select" className="margin-top-left-5 sort-by-dropdown w-20" onChange={this.handleChange2} name="sorttype">
      <option value="ASC">ASC</option>
      <option value="DESC">DESC</option>
     
    </Form.Control>

                </div>

                
      
				
                
              


			
	
                
                

                    </div>
                <table className="margin-top-10">
				<thead>
                
    
				<tr>
                {this.props.showselection!=undefined&&this.props.showselection==true?<th></th>:null}
				{
					tableheader
				}
                
                {this.props.showeditbutton==undefined||(this.props.showeditbutton!=undefined&&this.props.showeditbutton==true)?<th></th>:null}
                {this.props.showdeletebutton!=undefined&&this.props.showdeletebutton=='yes'?<th></th>:null}
                {this.props.activationcallback!=undefined?<th></th>:null}
                {this.props.showdatagriddropdown1=='yes' && this.props.councellordropdown=='yes' &&localStorage.getItem('UserRoleID')!=18?<th>Assign Councellor</th>:null}
                {this.props.showdatagriddropdown1=='yes'?<th></th>:null}
                {this.props.showdatagridevaluation=='yes'?<th></th>:null}
                {this.props.showdiscdetail=='yes'?<th></th>:null}
				
				</tr>
				</thead>
                {(this.state.showadvancefilter)?
                <tr>
                    {this.props.tableid==57?<th></th>:null}
                    {tableheaderfilter}
                    {this.props.showeditbutton==undefined||(this.props.showeditbutton!=undefined&&this.props.showeditbutton==true)?<th></th>:null}
                    {this.props.showdeletebutton!=undefined&&this.props.showdeletebutton=='yes'?<th></th>:null}
                    {this.props.activationcallback!=undefined?<th></th>:null}
                    {this.props.showdatagriddropdown1=='yes' &&localStorage.getItem('UserRoleID')!=18?<th></th>:null}
                   {this.props.showdatagriddropdown1=='yes'?<th></th>:null}
                   {this.props.showdatagridevaluation=='yes'?<th></th>:null}
                   {this.props.showdiscdetail=='yes'?<th></th>:null}
                </tr>
                :null
    }
	
	<tbody>
	{tablerows}
	</tbody>
</table>

<PaginationDiv>
<Pagination>
  <Pagination.First onClick={()=>{
      this.setState({'paginationrowstart':parseInt(''+0),'paginationrowend':parseInt(''+this.state.noofrows-1)},()=>{
        this.setState({activepage:parseInt(''+this.state.paginationrowstart)/this.state.noofrows})
     

      });
  }} />
  <Pagination.Prev onClick={()=>{if(this.state.paginationrowstart>0){

        this.setState({paginationrowstart:parseInt(''+this.state.paginationrowstart)-parseInt(''+this.state.noofrows)},()=>{
            //gbconsole.log('rowstart=====',this.state.paginationrowstart)
        this.setState({paginationrowend:parseInt(''+this.state.paginationrowstart)+(parseInt(''+this.state.noofrows-1))},()=>{
            //gbconsole.log('rowstart=',this.state.paginationrowstart)
            //gbconsole.log('rowend=',this.state.paginationrowend)
            this.setState({activepage:parseInt(''+this.state.paginationrowstart)/this.state.noofrows})
     

        })
        
        })

         }
    
    
    
    }} />
    


  
 
  { pagelink  }
 
   <Pagination.Next onClick={()=>{if(this.state.paginationrowend<(tablerows.length-1)){
       this.setState({paginationrowstart:parseInt(''+this.state.paginationrowstart)+parseInt(''+this.state.noofrows)})
       
       let rowend=parseInt(this.state.paginationrowend )+parseInt(''+this.state.noofrows);
       //gbconsole.log('rowend--=',rowend,'rowstart=',this.state.paginationrowstart);
       if(rowend>(tablerows.length-1)){
           let diff=(tablerows.length-1)-this.state.paginationrowend;
           rowend=this.state.paginationrowend+diff;
           
       }
       this.setState({paginationrowend:rowend},()=>{
        //gbconsole.log('rowstart=',this.state.paginationrowstart)
        //gbconsole.log('rowend=',this.state.paginationrowend)
        this.setState({activepage:parseInt(''+this.state.paginationrowstart)/this.state.noofrows})
     
     })

       
       }}} />
  <Pagination.Last onClick={()=>{
  var rowstart=0;
  var rowend=0;
  var quotient = Math.floor(tablerows.length/this.state.noofrows);
  var remainder = tablerows.length % this.state.noofrows;
    //gbconsole.log('quotient=',quotient)
    //gbconsole.log('remainder=',remainder)
    if(remainder>0){
        rowstart=parseInt(''+(quotient*this.state.noofrows));
        rowend=parseInt(''+(rowstart+(remainder-1)));

    }
    else{
        rowstart=parseInt(''+((quotient-1)*this.state.noofrows));
        rowend=parseInt(''+(rowstart+this.state.noofrows-1));
    }
    //gbconsole.log('rowstart=',rowstart);
    //gbconsole.log('rowend=',rowend);
    this.setState({'paginationrowstart':rowstart,'paginationrowend':rowend},()=>{
        //gbconsole.log('pagination rowstart:',this.state.paginationrowstart,"pagrowend:",this.state.paginationrowend);
        this.setState({activepage:parseInt(''+this.state.paginationrowstart)/this.state.noofrows})
     
    });


  }}/>
</Pagination>

</PaginationDiv>


                
            </div>
          
        )
    }
}

export default DataGrid
