import React from 'react';
import '../style.css';
import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl,label,input,h2,h3,h4,h5} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';
import * as BsIcons from "react-icons/bs";
import styled from 'styled-components';
import '../assets/styles/sb-admin-2.css';
import '../assets/styles/sb-admin-2.min.css';
import undraw_posting_photo from '../assets/img/undraw_posting_photo.svg';
import undraw_profile_1 from '../assets/img/undraw_profile_1.svg';
import undraw_profile_2 from '../assets/img/undraw_profile_2.svg';
import undraw_profile_3 from '../assets/img/undraw_profile_3.svg';
import undraw_profile from '../assets/img/undraw_profile.svg';
import profileicon from '../assets/images/profile_Icon.png';
import logoimage from '../assets/images/yoga.png';
import CanvasJSReact from '../assets/canvasjs.react';



class Home extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
          qdndone: '',
          qdnopen: '',
          qdnclosed: '',
          noofstore:'',
          ready: false,
          
        }
    }
    
    componentDidMount() { 

      //getdashboarddetails
      let postData = {
         
         
          
        "username":localStorage.getItem('FirstName')
         }
      let url = process.env.REACT_APP_DEBUG_URL+'/api/getdashboarddetails';
      let usertoken =localStorage.getItem("UserToken");
      

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+usertoken },
      body: JSON.stringify(postData)
    
  };

    fetch(url, requestOptions)
    .then(res => res.json())
    .then(result => {
      console.log(result);
      this.setState({
        qdndone:result.qdndone,
        qdnopen:result.qdnopen,
        qdnclosed:result.qdnclosed,
        noofstore:result.noofstore},
        ()=>{this.setState(
          {ready:true}
           )
           });

    })
    
        

        
       
      }

      


      render() { 
        const WorkArea = styled.div`
        background: white;
        left:250;
        height: 100vh;
        display: flex;
        
      `;

      const options = {
        title: {
          text: "Basic Column Chart in React"
        },
        data: [{				
                  type: "column",
                  dataPoints: [
                      { label: "Apple",  y: 10  },
                      { label: "Orange", y: 15  },
                      { label: "Banana", y: 25  },
                      { label: "Mango",  y: 30  },
                      { label: "Grape",  y: 28  }
                  ]
         }]
     }

     const options1 = {
			exportEnabled: true,
			animationEnabled: true,
			title: {
				text: "Website Traffic Sources"
			},
			data: [{
				type: "pie",
				startAngle: 75,
				toolTipContent: "<b>{label}</b>: {y}%",
				showInLegend: "true",
				legendText: "{label}",
				indexLabelFontSize: 16,
				indexLabel: "{label} - {y}%",
				dataPoints: [
					{ y: 18, label: "Direct" },
					{ y: 49, label: "Organic Search" },
					{ y: 9, label: "Paid Search" },
					{ y: 5, label: "Referral" },
					{ y: 19, label: "Social" }
				]
			}]}
     var CanvasJS = CanvasJSReact.CanvasJS;
     var CanvasJSChart = CanvasJSReact.CanvasJSChart;

        return (
            <div id="content-wrapper" className="d-flex flex-column">
            
            <div id="content">
              
              <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
               
              
               
               
                
                <ul className="navbar-nav ml-auto">
                 
                  <li className="nav-item dropdown no-arrow d-sm-none">
                    
                   
                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                     
                    </div>
                  </li>
                  
                  <li className="nav-item dropdown no-arrow mx-1">
                   
                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                      
                    </div>
                  </li>
                 
                  <li className="nav-item dropdown no-arrow mx-1">
                   
                   
                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
                      
                    </div>
                  </li>
                  <div className="topbar-divider d-none d-sm-block" />
                 
                  <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="mr-2 d-none d-lg-inline text-gray-600 small">{localStorage.getItem('FirstName')}</span>
                      <img className="img-profile rounded-circle" src={profileicon} />
                    </a>
                  
                     
                  </li>
                </ul>
              </nav>
             
              <div className="container-fluid">
              
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                  
                </div>
               
                <div className="row">
               
                  <div className="col-xl-3 col-md-6 mb-4" style={{display:localStorage.getItem('UserRoleID')==22?'none':'block'}}>
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Total Stores</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.noofstore}</div>
                          </div>
                          <div className="col-auto">
                            {/* <i className="fas fa-calendar fa-2x text-gray-300" /> */}
                            {/* <FcIcons.FcCalendar  /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                              QDN-OPEN</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.qdnopen}</div>
                          </div>
                          <div className="col-auto">
                          
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">QDN-ACTION DONE
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.qdndone}</div>
                          </div>
                          <div className="col-auto">
                            {/* <i className="fas fa-clipboard-list fa-2x text-gray-300" /> */}
                            {/* <BsIcons.BsClipboardData /> */}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              QDN-CLOSED</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.qdnclosed}</div>
                          </div>
                          <div className="col-auto">
                            {/* <i className="fas fa-comments fa-2x text-gray-300" /> */}
                            {/* <FaIcons.FaComments /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
              
              
             
              </div>
              
            </div>
          
            <footer className="sticky-footer bg-white">
              <div className="container my-auto" style={{backgroundColor:'#fff'}}>
                <div className="copyright text-center my-auto">
                  {/* <span>Copyright © Your Website 2021</span> */}
                  <span><a href='https://thinkmaster.technology' target='_blank'>Developed & Maintain by Thinkmaster Technology</a></span>
                </div>
              </div>
            </footer>
           
          </div>
        );
      }




}

export default Home;
 