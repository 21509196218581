import logo from './logo.svg';
import './App.css';
import Login from './Components/Login';
import Module1 from './Components/Module1';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link,useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
/*import "../node_modules/noty/lib/noty.css";  
import "../node_modules/noty/lib/themes/mint.css";  */
import Home from './Components/Home';
//import Merier from './Components/Design/Ecommerce/Merier';
// import Ehome from './Components/Design/Onky/Ehome';
// import Ehomeaboutus from './Components/Design/Onky/Ehomeaboutus';
// import Ehomecontactus from './Components/Design/Onky/Ehomecontactus';
// import Ehomeheader from './Components/Design/Onky/Ehomeheader';
// import Ehomefooter from './Components/Design/Onky/Ehomefooter';
// import Ehomecollection from './Components/Design/Onky/Ehomecollection';
// import Ehomesingle from './Components/Design/Onky/Ehomesingle';
import About from './Components/About';
import Overview from './Components/Overview';
import Sidebar from './Components/Sidebar';
import ForgotPassword from './Components/ForgotPassword';
import RecoverPassword from './Components/RecoverPassword';
import Dashboard from './Components/Dashboard';
import Form1 from './Examples/Form1';
import UserRole from './Components/UserRole';
import MenuMaster from './Components/MenuMaster';
import RoleMenu from './Components/RoleMenu';
import UserActivation from './Components/UserActivation';
import DropDownMaster from './Components/DropDownMaster';
import Studentcalendar from './Components/Studentcalendar';
import Staffcalendar from './Components/Staffcalendar'; 
import Userc from './Components/Userc';
import Invoice from './Components/Invoice';
import Enquirysuccess from './Components/Enquirysuccess';
import Invoicepayment from './Components/Invoicepayment';
import ChangePassword from './Components/ChangePassword';





class App extends React.Component
{
    // state = { view_count: 0 , view: 'settings'}
    state = { view_count: 0 , view: 'login',enquiryid:0,enquiryreference:'',qdnid:0,qdnno:'',qdnstatus:'',invadmissionrowid:0}
    constructor(props) {
      super(props);     
    }
    refreshEnquiryCallback=(cmp,enquiry_id,enqreference)=>{
      //gbconsole.log('enquiry---id=',enquiry_id)
      if(cmp=="logout"){
        this.logout()
       }
       else
       {
         if(cmp!='')
         this.setState({view_count : this.state.view_count + 1, view: cmp,enquiryid:enquiry_id,enquiryreference:enqreference})
       }
    }
   refreshStateEnquiry=(cmp,enquiry_id,enqreference)=>{
     //gbconsole.log('enquiry---id=',enquiry_id)
    if(cmp=="logout"){
      this.logout()
     }
     else
     {
       if(cmp!='')
       this.setState({view_count : this.state.view_count + 1, view: cmp,enquiryid:enquiry_id,enquiryreference:enqreference})
     }
   }

   refreshStateQdn=(cmp,qdnid,qdnno)=>{
    console.log('qdn---id=',qdnid)
    console.log('qdnno=',qdnno)
   if(cmp=="logout"){
     this.logout()
    }
    else
    {
      if(cmp!=''){
        const postdata={

         
          "qdnno":qdnno
         
        };
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'Authorization': 'Bearer '+ localStorage.getItem('UserToken')},
          body:JSON.stringify(postdata)
      };
      let url='http://localhost/scm/api/getqdndetails';
      url=process.env.REACT_APP_DEBUG_URL+'/api/getqdndetails';
      fetch(url, requestOptions).then(response => response.json()).then(data => { 
        var qdnstatus=data.Message;
        console.log('qdnstatus=',data.Message);
        this.setState({view_count : this.state.view_count + 1, view: cmp,qdnid:qdnid,qdnno:qdnno,qdnstatus:qdnstatus})
       
    }
        
        
        );
      
       
      }
     
    }
  }

    refreshState = (cmp) => {
      console.log(cmp)
      if(cmp=="logout"){
       this.logout()
      }
      else
      {
        if(cmp!='')
        this.setState({view_count : this.state.view_count + 1, view: cmp})
      }
      
      // //gbconsole.log(cmp);
      // this.setState({view: cmp})
    }

    refreshInvoiceState = (cmp,rowid) => {
      if(cmp=="logout"){
       this.logout()
      }
      else
      {
        if(cmp!='')
        this.setState({view_count : this.state.view_count + 1,invadmissionrowid:rowid},()=>{this.setState({view: cmp})})
      }
      
      // //gbconsole.log(cmp);
      // this.setState({view: cmp})
    }

    componentDidMount() { 
    
    }   

     CurrentTimeStamp=()=>{
      let date=new Date();      
      let bidtimestamp=date.toLocaleString();
      var seconds = date.getSeconds();
      var minutes = date.getMinutes();
      var hour = date.getHours();
      var year = date.getFullYear();
      var month = date.getMonth(); // beware: January = 0; February = 1, etc.
      month = month + 1;
      var day = date.getDate();
      bidtimestamp ='' + day + '/' + month + '/' + year + ', ' + hour + ':' + minutes + ':' + seconds;
     
     return bidtimestamp ;
    }
    

    logout= () =>{
    
   
//gbconsole.log('logout')
  const postlogout={

    "LoginId":localStorage.getItem('LoginID'),
    "branchID":0,
    "TimeStamp":this.CurrentTimeStamp()
   
  };
  //gbconsole.log(postlogout)

  const requestOptions1 = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'Authorization': 'Bearer '+ localStorage.getItem('UserToken')},
          body:JSON.stringify(postlogout)
      };
	  let url='http://localhost/scm/api/logout';
 url=process.env.REACT_APP_DEBUG_URL+'/api/logout';
      fetch(url, requestOptions1)
      .then(response => response.json())
      .then(data => { 
        alert(data.message)
         this.setState({view_count : this.state.view_count + 1, view: "login"});
       
    }
        
        
        );

      return 

}

    render() {
      //gbconsole.log(this.state.view);
     // const Ehome=React.lazy(()=>import ('./Components/Design/Onky/Ehome'))
return(
 
<Router>
<Switch>
          <Route path="/about" component={About} />
          {/* <Route path="/ehome" component={Ehome} /> */}
          {/* <Route path="/ehomeaboutus" component={Ehomeaboutus} refreshCallback={this.refreshState}/>
          <Route path="/ehomecontactus" component={Ehomecontactus} refreshCallback={this.refreshState} /> */}
          
          
          {/* <Route path="/ehome">
          <Ehomeheader currentlink='home' /><Ehome  /><Ehomefooter />

            </Route>
            <Route path="/ehomeaboutus">
            <Ehomeheader currentlink='about'/><Ehomeaboutus  /><Ehomefooter />

            </Route>

            <Route path="/ehomecontactus">
            <Ehomeheader currentlink='contact'/><Ehomecontactus  /><Ehomefooter />

            </Route>

            <Route path="/ehomecollection">
            <Ehomeheader currentlink='collection'/><Ehomecollection  /><Ehomefooter />

            </Route>

            <Route path="/ehomesingle">
            <Ehomeheader currentlink='single'/><Ehomesingle  /><Ehomefooter />

            </Route> */}

          <Route path="/studentadmission">
          <DropDownMaster tablename={'Admission master'} columnstoview={['First Name','Middle Name','Last Name','Fathers name','Course','Phone No']} showlist={'no'} />

            </Route>
            <Route path="/admission">
          <DropDownMaster tablename={'AdmissionTYI'} tablecaption={'Admission Master'} refreshInvoiceCallback={this.refreshInvoiceState} columnstoview={['Course','Session','First Name','Middle Name','Last Name','Mobile No']} showlist={'no'} />
         {
          (() => {
            switch(this.state.view) {

              case 'invoice': return  <Router><Invoice admissionid={this.state.invadmissionrowid}/></Router>; break;
              default: 
            }
            
          
        })()
           }
            </Route>
            <Route path='/enquiry'>
            <DropDownMaster tablename={'Enquiry Master'} tablecaption={'Enquiry Details'} refreshEnquiry={this.refreshEnquiryCallback}   showlist={'no'} />
            {
          (() => {
            switch(this.state.view) {

              case 'enqcallback': return  <Router><Enquirysuccess enqreferencenumber={this.state.enquiryreference}/></Router>; break;
              default: 
            }
            
          
        })()
           }
            </Route>
          <Route path="/invoice">
          <Invoice />
           </Route>
          <Route path="/">
           
         



        {/* return ( */}
          <div className="testdiv" style={{width:'100%',height:'100%'}}>


          
           
            {
              


              (() => {
                
                switch(this.state.view) {

                  case 'login' : return <Login refreshCallback={this.refreshState} />; break;
                  case 'home': return  <Router><Sidebar currentLink={'home'} refreshCallback={this.refreshState} /><Home/></Router>; break;
                // case 'home': return  <Router><Sidebar currentLink={'home'} refreshCallback={this.refreshState} /><Merier/></Router>; break;
                  case 'overview': return  <Router><Sidebar currentLink={'overview'} refreshCallback={this.refreshState} /><Overview/></Router>; break;
                  case 'forgotpassword': return  <Router><ForgotPassword refreshCallback={this.refreshState} /></Router>; break;
                  case 'recoverpassword': return  <Router><RecoverPassword refreshCallback={this.refreshState} /></Router>; break;
                  case 'example1' : return <Router><Sidebar currentLink={'example1'} refreshCallback={this.refreshState} /><Form1/></Router>; break;
                  case 'dashboard' : return <Router><Sidebar currentLink={'dashboard'} refreshCallback={this.refreshState} /><Dashboard/></Router>; break;
                  case 'roleassigned' : return <Router><Sidebar currentLink={'roleassigned'} refreshCallback={this.refreshState} /><UserRole/></Router>; break;
                  case 'menumaster' : return <Router><Sidebar currentLink={'menumaster'} refreshCallback={this.refreshState} /><MenuMaster/></Router>; break;
                  case 'rolemenu' : return <Router><Sidebar currentLink={'rolemenu'} refreshCallback={this.refreshState} /><RoleMenu/></Router>; break;
                  case 'useractivation' : return <Router><Sidebar currentLink={'useractivation'} refreshCallback={this.refreshState} /><UserActivation/></Router>; break;
                  case 'citymaster' : return <Router><Sidebar currentLink={'citymaster'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'City'}/></Router>; break;
                  case 'statemaster' : return <Router><Sidebar currentLink={'statemaster'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'State'}/></Router>; break;
                  case 'paymentmodes' : return <Router><Sidebar currentLink={'paymentmodes'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Payment Modes'}/></Router>; break;
                  case 'banknames' : return <Router><Sidebar currentLink={'banknames'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Bank Names'}/></Router>; break;
                  case 'country' : return <Router><Sidebar currentLink={'country'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Country'}/></Router>; break;
                  case 'gender' : return <Router><Sidebar currentLink={'gender'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Gender'}/></Router>; break;
                  case 'bloodgroup' : return <Router><Sidebar currentLink={'bloodgroup'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Blood Group'}/></Router>; break;
                  case 'designation' : return <Router><Sidebar currentLink={'designation'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Designation'}/></Router>; break;
                  case 'department' : return <Router><Sidebar currentLink={'department'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Department'}/></Router>; break;
                  case 'companyforaccounts' : return <Router><Sidebar currentLink={'companyforaccounts'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'CompanyForAccounts'}/></Router>; break;
                  case 'ledgers' : return <Router><Sidebar currentLink={'ledgers'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Ledgers'}/></Router>; break;
                  case 'religion' : return <Router><Sidebar currentLink={'religion'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Religion'}/></Router>; break;
                  case 'caste' : return <Router><Sidebar currentLink={'caste'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Caste'}/></Router>; break;
                  case 'subcaste' : return <Router><Sidebar currentLink={'subcaste'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Subcaste'}/></Router>; break;
                  case 'educationalqualification' : return <Router><Sidebar currentLink={'educationalqualification'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Educational Qualification'}/></Router>; break;
                  case 'educationalqualificationstream' : return <Router><Sidebar currentLink={'educationalqualificationstream'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Educational Qualification Stream'}/></Router>; break;
                  case 'nationality' : return <Router><Sidebar currentLink={'nationality'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Nationality'}/></Router>; break;
                  case 'houseforstudentsgroup' : return <Router><Sidebar currentLink={'houseforstudentsgroup'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'House For Students Group'}/></Router>; break;
                  case 'mothertongue' : return <Router><Sidebar currentLink={'mothertongue'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Mother Tongue'}/></Router>; break;
                  case 'staffcategory' : return <Router><Sidebar currentLink={'staffcategory'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Staff Category'}/></Router>; break;
                  case 'teachingsubjects' : return <Router><Sidebar currentLink={'teachingsubjects'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Teaching Subjects'}/></Router>; break;
                  case 'teachingperiods' : return <Router><Sidebar currentLink={'teachingperiods'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Teaching Periods'}/></Router>; break;
                  case 'classrooms' : return <Router><Sidebar currentLink={'classrooms'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Classrooms'}/></Router>; break;
                  case 'jobvacancy' : return <Router><Sidebar currentLink={'jobvacancy'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Job Vacancy'}/></Router>; break;
                  case 'jobreferredby' : return <Router><Sidebar currentLink={'jobreferredby'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Job Referred By'}/></Router>; break;
                  case 'branch' : return <Router><Sidebar currentLink={'branch'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Branch'}/></Router>; break;
                  case 'course' : return <Router><Sidebar currentLink={'course'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Course'}/></Router>; break;
                  case 'academicyear' : return <Router><Sidebar currentLink={'academicyear'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Academic year'}/></Router>; break;
                  case 'semester' : return <Router><Sidebar currentLink={'semester'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Semester'}/></Router>; break;
                  case 'company' : return <Router><Sidebar currentLink={'company'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Company master'} columnstoview={['Company name','Address','Email','Email2','Phone number','Phone Number 2','Website']} /></Router>; break;
                  case 'class' : return <Router><Sidebar currentLink={'class'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Class'}/></Router>; break;
                  case 'section' : return <Router><Sidebar currentLink={'section'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Section'} /></Router>; break;
                  case 'studentcalendar' :  return <Router><Sidebar currentLink={'studentcalendar'} refreshCallback={this.refreshState} /><Studentcalendar  /></Router>; break;
                  case 'staffcalendar' :  return <Router><Sidebar currentLink={'staffcalendar'} refreshCallback={this.refreshState} /><Staffcalendar  /></Router>; break;
                  case 'admission' : return <Router><Sidebar currentLink={'admission'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Admission master'} columnstoview={['First Name','Middle Name','Last Name','Fathers name','Course','Phone No']} /></Router>; break;
                  case 'enquiry':return  <Router><Sidebar currentLink={'enquiry'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Enquiry Master'} refreshCallback={this.refreshStateEnquiry} columnstoview={['Enquirydate','Enq ref','First Name','Middle Name','Last Name','contactno','Course']} showdatagriddropdown1={'yes'} /></Router>; break;
                  case 'enquirysource':return  <Router><Sidebar currentLink={'enquirysource'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Enquiry Source'} /></Router>; break;
                  case 'createuser':return  <Router><Sidebar currentLink={'createuser'} refreshCallback={this.refreshState} /><Userc /></Router>; break;
                  case 'followup':return  <Router><Sidebar currentLink={'followup'}  refreshCallback={this.refreshState} /><DropDownMaster enquiryid={this.state.enquiryid} enquiryreference={this.state.enquiryreference} refreshCallback={this.refreshState} columnstoview={['Enquiry Reference','Followup Date','Communication Mode','Current Status','Follow up status','Comments']}      tablename={'FollowupMaster'}/></Router>; break;
                  case 'communicationmode':return  <Router><Sidebar currentLink={'communicationmode'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Mode of communication'} /></Router>; break;
                  case 'followupstatus':return  <Router><Sidebar currentLink={'followupstatus'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Followup status'} /></Router>; break;
                  case 'statusfollowup':return  <Router><Sidebar currentLink={'statusfollowup'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Status Followup'} /></Router>; break;
                  case 'finalremarks':return  <Router><Sidebar currentLink={'finalremarks'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Final Remarks'} /></Router>; break;
                  case 'feeshead':return  <Router><Sidebar currentLink={'feeshead'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Fees Codes'} /></Router>; break;
                  case 'coursefees':return  <Router><Sidebar currentLink={'coursefees'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Course Fees'} columnstoview={['Course','Session']}  /></Router>; break;
                  case 'accountingdivision':return  <Router><Sidebar currentLink={'accountingdivision'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Accounting Division'} /></Router>; break;
                  case 'gencode':return  <Router><Sidebar currentLink={'gencode'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Gencode'} columnstoview={['Gencode','Description']}  /></Router>; break;
                  case 'receiptbook':return  <Router><Sidebar currentLink={'receiptbook'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Receipt Book'} /></Router>; break;
                  case 'finecode':return  <Router><Sidebar currentLink={'finecode'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Fine Codes'} /></Router>; break;
                  case 'feecategory':return  <Router><Sidebar currentLink={'finecode'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Fee Categories'} /></Router>; break;
                  case 'admissiontyi':return  <Router><Sidebar currentLink={'admissiontyi'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'AdmissionTYI'} tablecaption={'Admission Master'} columnstoview={['Course','Session','Student User ID','First Name','Middle Name','Last Name','Mobile No','Enquiry Ref No']} /></Router>; break;
                  case 'maritalstatus':return <Router><Sidebar currentLink={'maritalstatus'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Marital Status'} tablecaption={'Marital Status'} /></Router>; break;
                  case 'informationsource':return <Router><Sidebar currentLink={'informationsource'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Information Source'}  /></Router>; break;
                  case 'occupation':return <Router><Sidebar currentLink={'occupation'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Occupation'}  /></Router>; break;
                  case 'joiningpurpose':return <Router><Sidebar currentLink={'joiningpurpose'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Joining Purpose'}   /></Router>; break;
                  case 'hostelfacility':return <Router><Sidebar currentLink={'hostelfacility'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Hostel Facility Master'}   /></Router>; break;
                  case 'invoice': return  <Router><Invoice/></Router>; break;
                  case 'invoicepayment': return  <Router><Sidebar currentLink={'invoicepayment'} refreshCallback={this.refreshState} /><Invoicepayment/></Router>; break;
                  case 'studentapplication':return <Router><Sidebar currentLink={'studentapplication'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Student Application'} refreshCallback={this.refreshStateEnquiry} showdatagriddropdown1={'yes'} columnstoview={['Application Ref','Course','Apply date','First Name','Middle Name','Last Name','Date of Birth','Current status']}   /></Router>; break;
                  case 'applicationstatus':return <Router><Sidebar currentLink={'applicationstatus'} refreshCallback={this.refreshState} /><DropDownMaster tablename={'Application Status'}    /></Router>; break;
                  case 'studentapplicationfollowup':return  <Router><Sidebar currentLink={'studentapplicationfollowup'}  refreshCallback={this.refreshState} /><DropDownMaster enquiryid={this.state.enquiryid} enquiryreference={this.state.enquiryreference} refreshCallback={this.refreshState} columnstoview={['Application Ref','Application Followup Status','Application Followup Date','Application Followup Comments']} showdatagridevaluation={'yes'}     tablename={'Student Application Details'}/></Router>; break;
                  case 'studentapplicationmarks':return  <Router><Sidebar currentLink={'studentapplicationmarks'}  refreshCallback={this.refreshState} /><DropDownMaster enquiryid={this.state.enquiryid} enquiryreference={this.state.enquiryreference} refreshCallback={this.refreshState}      tablename={'Student Evaluation'}/></Router>; break;
                  case 'pincode':return  <Router><Sidebar currentLink={'pincode'}  refreshCallback={this.refreshState} /><DropDownMaster       tablename={'Pincode'}/></Router>; break;
                  // case 'ecomproductcategory':return  <Router><Sidebar currentLink={'ecomproductcategory'}  refreshCallback={this.refreshState} /><DropDownMaster       tablename={'Product Category'} columnstoview={['Category Name','Description']}/></Router>; break;
                  // case 'ecomproductbrand':return  <Router><Sidebar currentLink={'ecomproductbrand'}  refreshCallback={this.refreshState} /><DropDownMaster       tablename={'Brand'}/></Router>; break;
                  // case 'ecomproductvariantgroup':return  <Router><Sidebar currentLink={'ecomproductvariantgroup'}  refreshCallback={this.refreshState} /><DropDownMaster       tablename={'Product Variant Group'}/></Router>; break;
                  // case 'ecomproductvariant':return  <Router><Sidebar currentLink={'ecomproductvariant'}  refreshCallback={this.refreshState} /><DropDownMaster       tablename={'Product Variant'}/></Router>; break;
                  // case 'ecomproduct':return  <Router><Sidebar currentLink={'ecomproduct'}  refreshCallback={this.refreshState} /><DropDownMaster       tablename={'Product'}/></Router>; break;
                  // case 'ecomproductattribute':return  <Router><Sidebar currentLink={'ecomproductattribute'}  refreshCallback={this.refreshState} /><DropDownMaster       tablename={'Product Attribute'}/></Router>; break;
                 // case 'discrepancy':return  <Router><Sidebar currentLink={'discrepancy'}  refreshCallback={this.refreshState} /><DropDownMaster  refreshCallback={this.refreshStateQdn}      tablename={'Discrepancy'}  chiltablename={'QDN Details'}  joinkey={'QDN NO'} columnstoview={['Store Name','QDN NO','INV ID','Docket NO','QDN DATE','INV DATE','Docket Date','QDN STATUS','Rectified By','Rectified Invoice Date']} showdiscdetail={'yes'} showdeletebutton={'yes'}/></Router>; break;
                  case 'discrepancy':return  <Router><Sidebar currentLink={'discrepancy'}  refreshCallback={this.refreshState} /><DropDownMaster showdeletebutton={'yes'}  refreshCallback={this.refreshStateQdn} formheader={'Discrepancy Report All'}      tablename={'Discrepancy'}  chiltablename={'QDN Details'}  joinkey={'QDN NO'} columnstoview={['Store Name','QDN NO','Ginesys Transfer Out Number','Docket NO','QDN DATE','Ginesys Transfer Out Date','Docket Date','QDN STATUS','Rectified By','Rectified Invoice Date']} showdiscdetail={'yes'} /></Router>; break;
                  case 'discrepancy2':return  <Router><Sidebar currentLink={'discrepancy2'}  refreshCallback={this.refreshState} /><DropDownMaster onlystore={'yes'}  refreshCallback={this.refreshStateQdn} formheader={'Create Discrepancy Report'}      tablename={'Discrepancy'}  chiltablename={'QDN Details'}  joinkey={'QDN NO'} columnstoview={['Store Name','QDN NO','Ginesys Transfer Out Number','Docket NO','QDN DATE','Ginesys Transfer Out Date','Docket Date','QDN STATUS','Rectified By','Rectified Invoice Date']} showdiscdetail={'yes'} showstoreonly /></Router>; break;
                 
                  case 'opendiscrepancy':return  <Router><Sidebar currentLink={'opendiscrepancy'}  refreshCallback={this.refreshState} /><DropDownMaster   refreshCallback={this.refreshStateQdn}  formheader={'Current Open Discrepencies'}    tablename={'Discrepancy'}  chiltablename={'QDN Details'}  joinkey={'QDN NO'} columnstoview={['Store Name','QDN NO','Ginesys Transfer Out Number','Docket NO','QDN DATE','Ginesys Transfer Out Date','Docket Date','QDN STATUS','Rectified By','Rectified Invoice Date']} showdiscdetail={'yes'} qdnstatus='open'/></Router>; break;
                 
                  case 'qdndetails':return  <Router><Sidebar currentLink={'qdndetails'}  refreshCallback={this.refreshState} /><DropDownMaster qdnid={this.state.qdnid} qdnno={this.state.qdnno} qdnstatus={this.state.qdnstatus} refreshCallback={this.refreshState} columnstoview={['QDN NO','QDN TYPE','Barcode no','Quantity','Store Remarks']}     tablename={'QDN Details'} backbutton={true} backto={'discrepancy'}/></Router>; break;
                  case 'changepassword': return  <Router><Sidebar currentLink={'changepassword'} refreshCallback={this.refreshState} /><ChangePassword/></Router>; break; 
                  case 'qdndetails2':return  <Router><Sidebar currentLink={'qdndetails2'}  refreshCallback={this.refreshState} /><DropDownMaster  refreshCallback={this.refreshState} columnstoview={['Store Name','QDN NO','INV ID','Docket NO','QDN DATE','Ginesys Transfer Out Date','Docket Date','QDN STATUS','Rectified By','Rectified Invoice Date','QDN TYPE','Barcode no','Quantity','Store Remarks']} parenttable={'Discrepancy'} parentfields={['Store Name','Ginesys Transfer Out Number','Docket NO','QDN DATE','Ginesys Transfer Out Date','Docket Date','QDN STATUS','Rectified By','Rectified Invoice Date']} formheader={'Item Wise Detail Report'}     tablename={'QDN Details'}  joinkey={'QDN NO'} AdvanceFilter={'1'} showeditbutton={false}  /></Router>; break;
                  default: 
                
                  
                  return <div></div>;
                }
              })()
            }
          </div>
        {/* ); */}

        </Route>
        
         
          
        </Switch>
  </Router>
);
  }
}

export default App;

